import "./App.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import PostContainer from "./components/PostsContainer";
import PostDrawer from "./components/PostDrawer";
import Helmet from "./components/common/Helmet";
function App() {
  return (
    <div className="App">
      <Helmet title="Charcoal Social" />
      <Header />
      <PostDrawer className="drawer" />
      <PostContainer />
      <Footer />
    </div>
  );
}

export default App;
