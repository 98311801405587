import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Link, Badge } from "@chakra-ui/react";
import "./linkPost.css";

const LinkPost = (props) => {
  return (
    <Box
      className="code-post-container"
      maxW="xl"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Box p="6">
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {props.title}
        </Box>
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2" colorScheme="red">
            Link
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {props.username}
          </Box>
        </Box>
      </Box>
      <Link className="link-content" href={props.content} isExternal>
        {props.title} <ExternalLinkIcon mx="2px" />
      </Link>
    </Box>
  );
};

export default LinkPost;
