import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  Textarea,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Stack,
  Select,
  Box,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { flairOptions } from "./shared";
import axios from "axios";

import "./postDrawer.css";

const PostDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const [flair, setFlair] = useState("None");
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleTitleChange = (event) => setTitle(event.target.value);
  const handleContentChange = (event) => setContent(event.target.value);
  const handleUsernameChange = (event) => setUsername(event.target.value);
  const handleFlairChange = (event) => {
    if (event.target.value !== "Select Flair") {
      setFlair(event.target.value);
    }
  };

  const btnRef = React.useRef();
  const toast = useToast();

  // clear state when closing post menu
  const clearState = () => {
    setTitle("");
    setContent("");
    setUsername("");
    setFlair("None");
  };

  // notify user that post was created
  const makeSuccessToast = () => {
    toast({
      title: "Delivering the tinder!",
      description: "We are kindling the fire. This should be a good one!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  // warn user that certain fields are required
  const makeWarningToast = () => {
    toast({
      title: "Hey there!",
      description: "Please fill out the Title, Content, and Username! Thanks!",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  };

  // notify user that the API returned an error
  const makeErrorToast = () => {
    toast({
      title: "Sorry!",
      description: "We had an issue making your post!",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  // close post drawer and clear state
  const closeDrawer = () => {
    clearState();
    onClose();
  };

  // make post to api and clear state
  const makePost = async () => {
    setButtonLoading(true);
    const post = {
      title: title,
      username: username,
      content: content,
      flair: flair,
    };
    const response = await axios.post(
      "https://posts-api.calebf.workers.dev/posts",
      post
    );
    setButtonLoading(false);
    closeDrawer();

    if (response.status === 200) {
      makeSuccessToast();
    } else {
      makeErrorToast();
    }
  };

  return (
    <Box w="100%" p={8} color="white">
      <Button
        className="button-formatting"
        ref={btnRef}
        colorScheme="green"
        onClick={onOpen}
      >
        Create Post
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create your Post!</DrawerHeader>
          <DrawerBody>
            <Stack spacing={3}>
              <Box>
                <Input
                  onChange={handleTitleChange}
                  size="lg"
                  placeholder="Title"
                />
              </Box>
              <Box>
                <Input onChange={handleUsernameChange} placeholder="Username" />
              </Box>
              <Box>
                <Textarea
                  onChange={handleContentChange}
                  size="md"
                  placeholder="Tell us something cool! If you are posting an image or a link just provide the URL."
                />
              </Box>
              <Select onChange={handleFlairChange} placeholder="Select Flair">
                {flairOptions.map((flair) => (
                  <option value={flair}>{flair}</option>
                ))}
              </Select>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer}>
              Cancel
            </Button>
            {title && content && username ? ( // if all fields exist, make button green, else gray
              <Button colorScheme="green" onClick={makePost}>
                Submit
              </Button>
            ) : (
              <Button
                isLoading={buttonLoading}
                colorScheme="gray"
                onClick={makeWarningToast}
              >
                Submit
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
export default PostDrawer;
