import { useEffect, useState } from "react";
import CodePost from "./posts/CodePost";
import ImagePost from "./posts/ImagePost";
import TextPost from "./posts/TextPost";
import LinkPost from "./posts/LinkPost";
import axios from "axios";

import "./postContainer.css";

const PostContainer = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // retrieve posts from API
      const postResponse = await axios.get(
        "https://posts-api.calebf.workers.dev/posts"
      );
      setPosts(postResponse.data);
    }
    fetchData();
  }, []);

  // check if content is in image format
  const isImagePost = (content) => {
    const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g;
    return regex.test(content);
  };

  // check if content is in link format
  const isLinkPost = (content) => {
    return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
      content
    );
  };

  return (
    <div className="posts-container">
      {posts.map(function (post, i) {
        if (isImagePost(post.content)) {
          return (
            <ImagePost
              title={post.title}
              content={post.content}
              username={post.username}
              flair={post.flair}
            />
          );
        }

        if (isLinkPost(post.content)) {
          return (
            <LinkPost
              title={post.title}
              content={post.content}
              username={post.username}
              flair={post.flair}
            />
          );
        }
        // if flair is code, return code post
        if (post.flair === "code" || post.flair === "Code 💻") {
          return (
            <CodePost
              title={post.title}
              content={post.content}
              username={post.username}
              flair={post.flair}
            />
          );
        }

        return (
          <TextPost
            title={post.title}
            content={post.content}
            username={post.username}
            flair={post.flair}
          />
        );
      })}
    </div>
  );
};

export default PostContainer;
