import { Heading } from "@chakra-ui/react";
import "./header.css";

const Header = () => {
  return (
    <div className="header-container">
      <Heading className="header" as="h2" size="2xl">
        Charcoal 💎
      </Heading>
    </div>
  );
};

export default Header;
