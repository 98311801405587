import { Box, Stack, Code, Badge } from "@chakra-ui/react";
import { flairOptions } from "../shared";
import "./codePost.css";

const CodePost = (props) => {
  return (
    <Box
      className="code-post-container"
      maxW="xl"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Box p="6">
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {props.title}
        </Box>
        <Box display="flex" alignItems="baseline">
          <Stack direction="row">
            <Badge borderRadius="full" px="2" colorScheme="teal">
              Code
            </Badge>
            {flairOptions.includes(props.flair) && (
              <Badge borderRadius="full" px="2" colorScheme="teal">
                {props.flair}
              </Badge>
            )}
          </Stack>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
           <div className="username-style">
              {props.username}
            </div>
          </Box>
        </Box>
      </Box>
      <Code>{props.content}</Code>
    </Box>
  );
};

export default CodePost;
